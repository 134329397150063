import { useRef, useState } from "react";
import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';
import 'react-credit-cards/es/styles-compiled.css';

import './App.css'
import {
  Input, Error, Button, Field, Label
} from '@cloudez/cloudez-design-system';
import queryString from 'query-string';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const theme = {
  success: '#7AD696',
  alert: '#EFA869',
  error: '#E86969',
  label: '#4A90E1',
  favorite: '#E9CF66',
  successNew: '#29D975',
  alertNew: '#F8820B',
  errorNew: '#FB264B',
  labelNew: '#353FF2',
  favoriteNew: '#FFBF2D',
  interface1: '#FFFFFF',
  interface2: '#F5F8FF',
  interface3: '#E8EAEF',
  interface4: '#CFD3DE',
  interface5: '#64728C',
  interface6: '#545C68',
  interface7: '#333333',
  darkMode: false,
  brand_primary_color: !queryString.parse(window.location.href).brand_primary_color ? "black" : queryString.parse(window.location.href).brand_primary_color,
  brand_secondary_color: !queryString.parse(window.location.href).brand_secondary_color ? "white" : queryString.parse(window.location.href).brand_secondary_color,
};


function App() {
  const formRef = useRef()
  const [card, setCard] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: ''
  })
  const [focus, setFocus] = useState('number')
  const [loading, setLoading] = useState(false)
  const [cardErrors, setCardErrors] = useState([]);

  const onChange = e => {
    setCard(card => ({
      ...card,
      [e.target.name]: e.target.value
    }))
  }

  const onFocus = e => {
    setFocus(e.target.name);
  }

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)
    setCardErrors([])

    const pbToken = "adbe17a895ed9d82e39f7c1801228088"
    const $PB = window.$PB
    
    const { number: { value: number }, name: { value: name }, expiry: { value: expiry }, cvc: { value: cvc } } = formRef.current.elements

    if (process.env.NODE_ENV !== "development") {
      try {
        $PB.setMerchant(pbToken)

        $PB.setCard({
          "cc_prevsaved": "",
          "cc_holder": name,
          "cc_number": number.replaceAll(' ', ''),
          "cc_expiration": expiry,
          "cc_cvv": cvc,
          "wallet_type": null,
          "wallet_payload": null,
          "soft_descriptor": "TEST",
          "cc_save": 1,
          "cc_auth": 0
        });

        $PB.doAddToken((token) => {
          let params = new URLSearchParams(window.location.search);
          const uuid = params.get("uuid")

          if (token)
            fetch("https://staging.api.cloudez.io/v3/creditcard-uuid/", {
              method: 'post',
              body: JSON.stringify({
                "uuid": uuid,
                "token": token.token,
                "display_number": number.substr(number.length - 4),
              }),
              headers: {
                "Content-Type": "application/json"
              }
            }).then(e => {
              const domain = queryString.parse(window.location.href).domain
              window.parent.postMessage('credit_created', domain)
            }).catch(e => {
              setLoading(false)
              setCardErrors(e)
            })
        }, (e) => {
          setLoading(false)
          setCardErrors(e)
        });
      } catch (e) {
        setLoading(false)
        setCardErrors(e)
      }
    } else {
      const token = '5e2d716cc022bf9985a265c5f6ea268561aa2705861b2'
      const uuid = queryString.parse(window.location.search).uuid

      fetch("http://localhost:8000/v3/creditcard-uuid/", {
        method: 'post',
        body: JSON.stringify({
          "uuid": uuid,
          "token": token,
          "display_number": `XXXX-XXXX-XXXX-${number.substr(number.length - 4)}`,
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(e => {
        const domain = queryString.parse(window.location.href).domain
        window.parent.postMessage('credit_created', domain)
      }).catch(e => {
        setLoading(false)
        setCardErrors(e)
      })
    }
  }

  return (
    <div id="main">
      <Cards
        cvc={card.cvc}
        expiry={card.expiry}
        name={card.name}
        number={card.number}
        focused={focus}
      />
      <form ref={formRef} onSubmit={submit}>
        <Field theme={theme}>
          <Label theme={theme}>Número do cartão</Label>
          <InputMask
            theme={theme}
            mask="9999 9999 9999 9999"
            maskChar={null}
            name="number"
            onChange={onChange}
            onFocus={onFocus}
            error={!!cardErrors}
          >
            {inputProps => <Input theme={theme} {...inputProps} />}
          </InputMask>
        </Field>
        <Field theme={theme}>
          <Label theme={theme}>Nome completo</Label>
          <Input
            theme={theme}
            name="name"
            onChange={onChange}
            onFocus={onFocus}
            error={!!cardErrors}
          />
          {cardErrors?.message?.msg?.includes("cc_holder") ? <Error>Nome vazio ou inválido</Error>:null}
        </Field>
        <div style={{
          width: '100%',
          display: 'flex'
        }}>
          <Field theme={theme} style={{
            width: "39%",
            marginRight: 30
          }}>
            <Label theme={theme}>VALIDADE</Label>
            <InputMask
              theme={theme}
              mask="99/9999"
              maskChar={null}
              name="expiry"
              onChange={onChange}
              onFocus={onFocus}
              error={!!cardErrors}
            >
              {inputProps => <Input theme={theme} {...inputProps} />}
            </InputMask>
            {cardErrors?.message?.msg?.includes("cc_expiration") && <Error>Validade vazia ou inválida</Error>}
          </Field>
          <Field theme={theme} style={{
            width: "39%"
          }}>
            <Label theme={theme}>CVC</Label>
            <Input
              theme={theme}
              maxLength="4"
              name="cvc"
              onChange={onChange}
              onFocus={onFocus}
              error={!!cardErrors}
            />
            {cardErrors?.message?.msg?.includes("cc_cvv") && <Error>CVV vazio ou inválido</Error>}
          </Field>
        </div>
        {cardErrors['error'] && <Error theme={theme}>Cartão inválido</Error>}
        <Button theme={theme} icon size="sm" type='submit' disabled={loading} style={{
          cursor: 'pointer',
          alignSelf: 'flex-end',
          marginRight: 30
        }}>{loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Criar'}</Button>
      </form>
    </div>
  );
}

export default App;
